import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Rólunk | A Peak Performance Gymről
			</title>
			<meta name={"description"} content={"A Peak Performance Gymben hiszünk az edzés erejében, amely nemcsak a testet, hanem az életet is átalakítja."} />
			<meta property={"og:title"} content={"Rólunk | A Peak Performance Gymről"} />
			<meta property={"og:description"} content={"A Peak Performance Gymben hiszünk az edzés erejében, amely nemcsak a testet, hanem az életet is átalakítja."} />
			<meta property={"og:image"} content={"https://tralorsbacki.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://tralorsbacki.com/img/5498150.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://tralorsbacki.com/img/5498150.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://tralorsbacki.com/img/5498150.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://tralorsbacki.com/img/5498150.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://tralorsbacki.com/img/5498150.png"} />
			<meta name={"msapplication-TileImage"} content={"https://tralorsbacki.com/img/5498150.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="space-between"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						lg-text-align="center"
						text-transform="uppercase"
						margin="0px 0px 8px 0px"
						color="--grey"
						font="--base"
						letter-spacing="2px"
					>
						A Peak Performance Gymről
					</Text>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Rólunk
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					A Peak Performance Gymben hiszünk az edzés erejében, amely nemcsak a testet, hanem az életet is átalakítja. Filozófiánk egyszerű: kiváló minőségű fitnesz megoldásokat és támogató légkört biztosítunk, amely arra ösztönzi tagjainkat, hogy túlteljesítsék céljaikat.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://tralorsbacki.com/img/5.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://tralorsbacki.com/img/6.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					A mi történetünk
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					A Peak Performance Gym abból a vágyból született, hogy olyan fitneszteret hozzon létre, amely áthidalja a szakadékot a félelmetes, kemény edzőtermek és a barátságos, de gyakran alul felszerelt környéki központok között. Megtaláltuk ezt az egyensúlyt egy olyan hellyel, amely büszke az inkluzivitásra, a csúcsminőségű felszerelésekre és a közösség által vezérelt környezetre.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-11"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Elkötelezettségünk
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
							<Strong>
								Inkluzivitás
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
							Minden edzettségi szintnek, korosztálynak és hátterűnek megfelelünk. Mindenki útja egyedi, mi pedig azért vagyunk itt, hogy támogassunk mindenkit.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
							<Strong>
								Oktatás
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
							Munkatársaink nemcsak oklevelesek – egész életen át tartó tanulók, akik a fitnesztrendek és az egészségügyi kutatások élvonalában maradnak.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
							<Strong>
								Innováció
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
							Kerüljük a divatokat, de elfogadjuk az értelmes innovációt – a tudományosan bizonyított edzéstechnikáktól a legújabb biztonságos, ergonomikus felszerelésekig.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						A mi terünk
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Attól a pillanattól kezdve, hogy belép a Peak Performance Gymbe, észreveszi a különbséget. Nyitott, szellős terek. Az állomások gondosan karbantartottak és átgondoltan vannak elrendezve. Ez több, mint egy edzőterem – ez egy olyan hely, ahol feszegetheti a határait, és felfedezheti az erejét.
					</Text>
				</Box>
				<Image src="https://tralorsbacki.com/img/7.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://tralorsbacki.com/img/8.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Határok nélkül
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Nem kötjük identitásunkat egyetlen helyszínhez vagy történethez, mert hisszük, hogy a Peak Performance szelleme egyetemes. Az akadályok leküzdéséről, a célok eléréséről és egy olyan közösség felépítéséről szól, amely túlmutat egy edzőterem falain.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});